<script>
  export let name
  // var svgToMiniDataURI = require('mini-svg-data-uri')

  import svgToMiniDataURI from 'mini-svg-data-uri'

  let svg = `<svg version="1.1"
     width="256" height="256"
     xmlns="http://www.w3.org/2000/svg">
  <rect width="60%" height="60%" fill="red" />
  <circle cx="150" cy="150" r="80" fill="blue" />
</svg>
`

  let link

  function update() {
    const icon = document.getElementById('icon')

    if (svg) {
      icon.href = svgToMiniDataURI(svg)
    }

    link = icon.outerHTML
  }

  $: svg, update()
</script>

<textarea style="width: 100%; resize: vertical; min-height: 10em;" bind:value={svg} />

<pre>{link}</pre>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
